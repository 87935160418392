import React, { Component } from 'react'
import {graphql, Link} from 'gatsby'
import {
	Container,
	Grid,
	Loader,
	Header
} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'
import ProductList from '../components/ProductList'
import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')


export default class ProductListPage extends Component {
	state = {
		loading: {},
		products: {},
		errors: {},
		timeOut1:undefined,
		itemsPerRow:4
	}

	componentDidMount()
	{
		this.fetchData()
		this.handler();
		window.addEventListener('resize', this.resizeTimerFunc)
	}

	componentWillUnmount() {
		clearTimeout(this.state.timeOut1);
		window.removeEventListener('resize', this.resizeTimerFunc);
	}

	resizeTimerFunc = () => {
		clearTimeout(this.state.timeOut1);
		const temp = setTimeout(this.handler, resizeTimer);
		this.setState({
			timeOut1:temp
		})
	}

	handler = () => {
		this.setState({
			windowWidth:window.innerWidth
		})
		if (window.innerWidth > tabletMaxWidth ) {
			this.setState({
				itemsPerRow: 4
			})
		} //tablet
		else if (window.innerWidth > mobileMaxWidth ) {
			this.setState({
				itemsPerRow: 4
			})
		} else {  //mobile
			this.setState({
				itemsPerRow: 2
			})
		}
	}

	fetchData = () => {
		const temp = this.props.data.negosyoNow.listPopularCategory;

		for(let i = 0; i< temp.length; i++) {
			this.setState(prevState => ({
				loading: {
					...prevState.loading, //prevent race conditions with other api in the future that will also set values for profile
					[temp[i].url]:true
				}
			}))
			Negosyo.getProductSearchTop(temp[i].url)
			.then((response)=>{
				this.setState(prevState => ({
					products: {
						...prevState.products,
						[temp[i].url]:response.slice( 0, 4 )
					},
					loading: {
						...prevState.loading, //prevent race conditions with other api in the future that will also set values for profile
						[temp[i].url]:false
					}
				}))
			}).catch((err)=>{

			})
		}
	}

	mapSection = ({name, url}, index) => {
		const {
			loading,
			products,
			itemsPerRow
		} = this.state
		return (
			<React.Fragment key={url}>
				{index===0 ? "" : <div className="ui divider"></div>}
				<Grid>
					<Grid.Column computer={14} tablet={12} mobile={12}>
						<Header className={`${styles.header}`}>
							{name}
						</Header>
					</Grid.Column>
					<Grid.Column computer={2} tablet={4} mobile={4} className={styles.text}>
						<Link to={`/${url}/`}> see more...</Link>
					</Grid.Column>
					<Grid.Column width={16} >
						{ (loading[url]===true || loading[url]===undefined) ? (
							<Loader active inline="centered"/>
						):(
							<ProductList
								products={products[url]}
								shown={itemsPerRow}
								grid={16}
								cameFrom={`Product List-${name}`}
								listName={`Product List-${name}`}
							/>
						)}
					</Grid.Column>
				</Grid>
			</React.Fragment>
		)
	}

	render() {

		const {
			mapSection
		} = this
		const {
			location,
			data
		} = this.props
		return (
			<Layout location={location}>
				<Seo
					title={"Product List"}
				/>
				<Container className={styles.container}>
					{data.negosyoNow.listPopularCategory.map(mapSection)}
					<div className={"ui segment "+styles.header+" "+styles.centerText}>
						<Link to={`/category/`}> See Other Categories </Link>
					</div>
				</Container>
			</Layout>
		)
	}
}

export const data = graphql`
	query {
		negosyoNow {
			listPopularCategory {
				name
				url
			}

		}
	}
`